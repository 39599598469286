<template>
  <div class="post-card relative" :class="{ 'grid grid-cols-2': isHorizontal }">
    <div
      class="relative fill-post-image"
      :class="{
        'mb-5': !isHorizontal,
      }"
    >
      <div
        ref="skeleton"
        class="animate-pulse absolute w-full h-full bg-amff-gray"
      />
    </div>
    <div :class="{ 'pl-7 ': isHorizontal }">
      <div
        class="text-lg sm:text-2xl md:text-3xl font-bold leading-tight mb-3"
        :class="{ 'lg:text-6xl': isHorizontal }"
      >
        <div class="animate-pulse w-5/6 text-transparent bg-amff-gray">
          . &nbsp;
        </div>
      </div>
      <div class="text-sm sm:text-md md:text-lg text-amff-light-gray">
        <div class="animate-pulse w-2/6 text-transparent bg-amff-gray">
          . &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: { isHorizontal: Boolean },
});
</script>
