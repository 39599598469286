import { PostDTO } from "@/domain/definitions";
import { PostModel } from "@/domain/models/PostModel";

import { useAPIRequest } from "./useAPIRequest";
import { ref, Ref } from "vue";

const posts = ref<PostModel[]>([]);

const usePosts = (
  lang: string
): {
  fetchPosts: () => Promise<void>;
  posts: Ref<PostModel[]>;
  isFetching: Ref<boolean>;
  getPostBySlug: (slug: string) => Promise<PostModel | undefined>;
} => {
  const { execute, isFetching, data } =
    useAPIRequest<{ data: PostDTO[] }>("/posts");

  async function fetchPosts() {
    await execute();

    if (data.value) {
      posts.value = data.value.data.map(
        (postDTO) => new PostModel(postDTO, lang)
      );
    }
  }

  async function getPostBySlug(slug: string): Promise<PostModel | undefined> {
    if (!posts.value.length) {
      await fetchPosts();
    }
    const post = posts.value.find((post) => post.slug === slug);
    return post;
  }

  return { fetchPosts, posts, isFetching, getPostBySlug };
};
export default usePosts;
