import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "max-w-container px-container py-14 md:py-28" }
const _hoisted_2 = { class: "\n        text-xl\n        sm:text-3xl\n        md:text-6xl\n        text-center\n        uppercase\n        font-display\n        mb-6\n        sm:mb-12\n        md:mb-24\n        lg:mb-40\n        text-amff-black-2024\n      " }
const _hoisted_3 = {
  key: 0,
  class: "\n          grid grid-cols-1\n          sm:grid-cols-1\n          md:grid-cols-2\n          lg:grid-cols-3\n          gap-y-14 gap-x-7\n        "
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "\n            grid grid-cols-1\n            sm:grid-cols-1\n            md:grid-cols-2\n            lg:grid-cols-3\n            gap-y-14 gap-x-7\n          " }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostCardSkeleton = _resolveComponent("PostCardSkeleton")
  const _component_PostCard = _resolveComponent("PostCard")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("post.all_news")), 1),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.isFetching)
          ? (_openBlock(), _createBlock("ul", _hoisted_3, [
              (_openBlock(), _createBlock(_Fragment, null, _renderList(10, (index) => {
                return _createVNode("li", {
                  key: 'post' + index,
                  class: { 'col-span-full': index === 1 }
                }, [
                  _createVNode(_component_PostCardSkeleton, {
                    isHorizontal: index === 1 && !_ctx.isMobile
                  }, null, 8, ["isHorizontal"])
                ], 2)
              }), 64))
            ]))
          : (!_ctx.isFetching && _ctx.posts?.length)
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode("ul", _hoisted_5, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.posts, (post, index) => {
                    return (_openBlock(), _createBlock("li", {
                      key: 'post' + post.id,
                      class: {
              'col-span-full': index === 0,
            }
                    }, [
                      _createVNode(_component_PostCard, {
                        post: post,
                        isHorizontal: index === 0 && !_ctx.isMobile
                      }, null, 8, ["post", "isHorizontal"])
                    ], 2))
                  }), 128))
                ]),
                (_ctx.jsonld)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
                      key: 0,
                      type: "application/ld+json",
                      innerHTML: _ctx.jsonld
                    }, null, 8, ["innerHTML"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}