
import { defineComponent, computed, onMounted } from "vue";
import PostCard from "@/components/PostCard.vue";
import PostCardSkeleton from "@/components/PostCardSkeleton.vue";
import usePosts from "@/composables/usePosts";
import { useMediaQuery } from "@vueuse/core";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import { asListItems, getPostJSONLD, withContext } from "@/domain/jsonld";

export default defineComponent({
  components: { PostCard, PostCardSkeleton },
  setup() {
    const { currentLang } = useRouteWithLang();

    const isMobile = useMediaQuery("(max-width: 1023px)");

    const { fetchPosts, posts, isFetching } = usePosts(currentLang.value);
    onMounted(async () => {
      if (!posts.value.length) {
        await fetchPosts();
      }
    });

    const jsonld = computed(() =>
      !posts.value
        ? undefined
        : JSON.stringify(
            withContext(
              asListItems(
                posts.value.map((post) =>
                  getPostJSONLD({ post, lang: currentLang.value })
                )
              )
            )
          )
    );

    return {
      posts,
      isFetching,
      isMobile,
      jsonld,
    };
  },
});
