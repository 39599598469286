<template>
  <router-link
    :to="{
      name: 'post',
      params: { lang: currentLang, slug: post.slug },
    }"
    class="post-card block relative"
    :class="{
      'grid grid-cols-2': isHorizontal,
      'text-amff-yellow-2024': isHover && !isMobile,
      'text-amff-black': !isHover && !isMobile,
    }"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    <div
      class="relative fill-post-image"
      :class="{
        'mb-5': !isHorizontal,
      }"
    >
      <div
        class="
          absolute
          w-full
          h-full
          border border-dashed border-amff-black-2024
        "
        :class="{ hidden: !showImage }"
      />
      <img
        :src="image"
        class="
          post-card__image
          w-full
          h-full
          object-cover
          absolute
          transition
          duration-200
          ease-in-out
        "
        :class="{ 'opacity-0': !showImage }"
        loading="lazy"
        @load="showImage = true"
      />
    </div>
    <div :class="{ 'pl-7 ': isHorizontal }">
      <div
        class="
          text-2xl
          md:text-3xl
          font-bold
          leading-tight
          mb-2
          text-amff-black-2024
        "
        :class="{ 'lg:text-6xl': isHorizontal }"
      >
        {{ post.title }}
      </div>
      <div class="text-md md:text-lg date text-amff-black-2024">
        {{ post.publishDateFormated }}
      </div>
    </div>
  </router-link>
</template>

<script>
import { useRoute } from "vue-router";
import { defineComponent, computed, ref } from "vue";
import { cdn } from "@/utils/cdn";
import { useMediaQuery } from "@vueuse/core";

export default defineComponent({
  props: { post: Object, isHorizontal: Boolean },
  setup(props) {
    const route = useRoute();
    const currentLang = computed(() => route.params.lang);
    const image = cdn(props?.post?.imagePost, "625x362.61");
    const showImage = ref(false);
    const isHover = ref(false);
    const isMobile = useMediaQuery("(max-width: 1023px)");

    return {
      currentLang,
      image,
      showImage,
      isHover,
      isMobile,
    };
  },
});
</script>

<style lang="scss">
/* Zoom-n-rotate Container */
.post-card {
  & .date:first-letter {
    text-transform: uppercase;
  }
  img {
    transform: rotate(0deg);
  }
}
@media (hover: hover) and (pointer: fine) {
  /* The Transformation */
  .post-card:hover img {
    transform: rotate(-5deg);
  }
}
</style>
