/** Note: not technically part of domain, more like infra. But for our purposes here, we have everything related to "pure data" in domain and it's good enough here */

import type { EventModel } from "./models/EventModel";
import type { LocationModel } from "./models/LocationModel";
import type { PostModel } from "./models/PostModel";

const BASE_URL = process.env.BASE_URL;
const stripHTMLTags = (str: string) =>
  str.replace(/<[^>]*>?/gm, "").replace(/&nbsp/gm, " ");
const toSchemaDate = (date: Date) => date.toISOString().slice(0, 10);

/* Removed this imports because they where giving memory issues
import {
  Article,
  Event,
  Place,
  Thing,
  WithContext,
  ItemList,
} from "schema-dts";
*/
type Thing = Record<string, unknown>;
type WithContext<T extends Thing> = { "@context": "https://schema.org" } & T;
type ItemList = { "@type": "ItemList"; itemListElement: Thing[] };
type Place = Thing;
type Event = Thing;
type Article = Thing;

export const withContext = <T extends Thing>(thing: T): WithContext<T> =>
  Object.assign({ "@context": "https://schema.org" }, thing) as WithContext<T>;

export const asListItems = <T extends Thing>(items: T[]): ItemList => ({
  "@type": "ItemList",
  itemListElement: items.map((item, i) => Object.assign({ position: i }, item)),
});

export const getLocationJSONLD = ({
  location,
}: {
  location: LocationModel;
}): Place & { "@type": "Place" } => ({
  "@type": "Place",
  name: location.name,
  url: location.link,
});

export const getEventJSONLD = ({
  event,
  lang,
}: {
  event: EventModel;
  lang: string;
}): Event => ({
  "@type": "Event",
  "@id": `${BASE_URL}${lang}/event/${event.slug}`,
  url: `${BASE_URL}${lang}/event/${event.slug}`,
  name: (event.title || "") + " - " + (event.subtitle || ""),
  startDate: event.startDate ? toSchemaDate(event.startDate) : undefined,
  endDate: event.endDate ? event.endDate.toISOString().slice(0, 10) : undefined,
  description: event.description ? stripHTMLTags(event.description) : undefined,
  eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
  eventStatus: "https://schema.org/EventScheduled",
  image: [`${BASE_URL}img/share.jpg`],
  location: event.location
    ? getLocationJSONLD({
        location: event.location,
      })
    : undefined,
  mainEntityOfPage: {
    "@type": "WebSite",
    "@id": `${BASE_URL}${lang}/event/${event.slug}`,
  },
});

export const getPostJSONLD = ({
  post,
  lang,
}: {
  post: PostModel;
  lang: string;
}): Article => ({
  "@type": "Article",
  "@id": `${BASE_URL}${lang}/news/${post.slug}`,
  datePublished: toSchemaDate(post.publishDate),
  description: stripHTMLTags(post.excerpt),
  headline: post.title,
  image: post.imagePost,
  thumbnailUrl: post.imagePost,
  url: `${BASE_URL}${lang}/news/${post.slug}`,
  publisher: {
    "@type": "Organization",
    "@id": "https://www.filmin.es",
    logo: "https://www.filmin.es/assets/img/instances/es/share-big.png",
    name: "Filmin",
  },
  author: "https://www.filmin.es",
  mainEntityOfPage: {
    "@type": "WebSite",
    "@id": `${BASE_URL}${lang}/news/${post.slug}`,
  },
});
