import { PostDTO } from "../definitions";
import { formatDateTimeRelativeForHumans } from "@/utils/dateTimeFormatter";

export class PostModel {
  readonly id: number;
  readonly slug: string;
  readonly title: string;
  readonly excerpt: string;
  readonly content: string;
  readonly imagePost: string;
  readonly publishDate: Date;
  readonly publishDateFormated: string;

  constructor(postDTO: PostDTO, lang: string) {
    this.id = postDTO.id;
    this.slug = postDTO.slug;
    this.title = postDTO.title;
    this.excerpt = postDTO.excerpt;
    this.content = postDTO.content;
    this.imagePost = postDTO.image_post;
    this.publishDate = new Date(postDTO.publish_date * 1000);
    this.publishDateFormated = formatDateTimeRelativeForHumans(
      postDTO.publish_date,
      true,
      lang
    );
  }
}
